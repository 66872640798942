exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.0.43.34\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".ImageSliderContentBlock__CustomAppendDots_dots-wrapper{display:block;line-height:0;list-style:none;margin:16px 0;padding:0;position:relative;text-align:center;width:100%}.ImageSliderContentBlock__CustomAppendDots_dot{cursor:pointer;display:inline-block;margin:0 8px;padding:0}.ImageSliderContentBlock__CustomAppendDots_dots-inside{bottom:0;position:absolute}", ""]);

// exports
exports.locals = {
	"dots-wrapper": "ImageSliderContentBlock__CustomAppendDots_dots-wrapper",
	"dotsWrapper": "ImageSliderContentBlock__CustomAppendDots_dots-wrapper",
	"dot": "ImageSliderContentBlock__CustomAppendDots_dot",
	"dots-inside": "ImageSliderContentBlock__CustomAppendDots_dots-inside",
	"dotsInside": "ImageSliderContentBlock__CustomAppendDots_dots-inside"
};