import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import RenderSlider from './RenderSlider';
import { SimpleText } from 'sana/texts';
import styles from './ImageSlider.module.scss';
import './ImageSlider.scss';

const ImageSlider = ({ model, id, isDesignerMode }) => {
  const elementId = `image-slider-content-block-${id}`;
  const data = useMemo(() => {
    if (!model) 
      return null;

    return {
      ...model,
      images: model.images
        .filter(i => i.visible)
        .sort(function (a, b) {
          return a.displayIndex - b.displayIndex;
        }),
    };
  }, [model]);

  if (!data) 
    return null;

  return (
    <div id={elementId} className="image-slider-content-block image-slider-wrapper">
      <span role="presentation" tabIndex={0} className="sr-only">{model.contentElementAltText ? model.contentElementAltText :  <SimpleText textKey={'ContentElementAltText'} />}</span>
      {isDesignerMode && <div className={styles.vdMinHeight} />}
      <RenderSlider modelData={data} />
    </div>
  );
};

ImageSlider.propTypes = {
  model: PropTypes.object,
  id:PropTypes.string,
  isDesignerMode: PropTypes.bool,
};

export default React.memo(ImageSlider);
