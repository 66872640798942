import React, {  useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from './image/Image';
import ImageContent from './imageContent/ImageContent';
import styles from './ImageWrapper.module.scss';

const ImageWrapper = ({
  isMobile,
  imagePath,
  mobileImagePath,
  imageAlterText,
  mobileImageAlterText,
  titleText,
  titleTextColor,
  subtitleText,
  subtitleTextColor,
  buttonText,
  buttonAltText,
  buttonTextColor,
  buttonBackgroundColor,
  buttonBottomBorderColor,
  buttonBackgroundHoverColor,
  buttonBottomBorderHoverColor,
  buttonLink,
  buttonPosition,
  spaceBetweenImages,
  imageScalingOption,
  afterLoad,
  visibleByDefault,
  activeDotIndex,
  isAutoPlay,
}) => {
  const imageSettings = {
    isMobile,
    imagePath,
    mobileImagePath,
    imageAlterText,
    mobileImageAlterText,
    spaceBetweenImages,
    imageScalingOption,
    afterLoad,
    visibleByDefault,
  };
  const contentSettings = {
    titleText,
    titleTextColor,
    subtitleText,
    subtitleTextColor,
    buttonText,
    buttonAltText,
    buttonTextColor,
    buttonBackgroundColor,
    buttonBottomBorderColor,
    buttonBackgroundHoverColor,
    buttonBottomBorderHoverColor,
    buttonLink,
  };

  let contentPosition;
  const ref = useRef(null);
  const [tabIndex,setTabIndex] = useState(-1);
  const parsedButtonPosition = parseInt(buttonPosition);
  if (parsedButtonPosition === 0)
    contentPosition = styles.contentCenter;
  else if (parsedButtonPosition === 1)
    contentPosition = styles.contentLeft;
  else
    contentPosition = styles.contentRight;

  useEffect(()=>{
    const activeSlide = ref.current.parentNode.parentNode.classList.contains('slick-active');
    const colnedSlide = ref.current.parentNode.parentNode.classList.contains('slick-cloned');

    if(activeSlide && !colnedSlide){
      setTabIndex(0);
    }
    else{
      setTabIndex(-1);
    }
  }, [ref, activeDotIndex]);

  return (
    <div    
      ref={ref} 
      aria-live={!isAutoPlay ? 'polite' : undefined}
      role="presentation"
      tabIndex={tabIndex}         
      className={classNames(
        styles.imageWrapper,
        contentPosition,
        styles.contentPadding,
      )}
    >
      <Image {...imageSettings} />
      <ImageContent {...contentSettings} tabIndex={tabIndex} />
    </div>
  );
};

ImageWrapper.propTypes = {
  isMobile: PropTypes.bool,
  imagePath: PropTypes.string.isRequired,
  mobileImagePath: PropTypes.string,
  imageAlterText: PropTypes.string,
  mobileImageAlterText: PropTypes.string,
  titleText: PropTypes.string,
  titleTextColor: PropTypes.string,
  subtitleText: PropTypes.string,
  subtitleTextColor: PropTypes.string,
  buttonText: PropTypes.string,
  buttonAltText: PropTypes.string,
  buttonTextColor: PropTypes.string,
  buttonBackgroundColor: PropTypes.string,
  buttonBottomBorderColor: PropTypes.string,
  buttonBackgroundHoverColor: PropTypes.string,
  buttonBottomBorderHoverColor: PropTypes.string,
  buttonLink: PropTypes.object,
  buttonPosition: PropTypes.number,
  spaceBetweenImages: PropTypes.number,
  imageScalingOption: PropTypes.number,
  afterLoad: PropTypes.func,
  visibleByDefault: PropTypes.bool,
};

export default ImageWrapper;
