import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './NavigationArrow.module.scss';
import { urlEscape } from '../../utils/helpers';
import { SimpleText } from 'sana/texts';

const NavigationArrow = ({ className, onClick, type, imgSrc }) => {
  let altText = 'PrevArrow';
  let navigationClass = 'image-slider-previous';

  if (type === 'nextArrow') {
    altText = 'NextArrow';
    navigationClass = 'image-slider-next';
  }

  return (
    <button
      onClick={onClick}
      className={classNames(
        className,
        styles.arrow,
        styles[type],
        navigationClass,

      )}
    ><span className="sr-only"><SimpleText textKey={altText} /></span>
      <img
        src={urlEscape(imgSrc)}
        alt={altText}
        aria-hidden
      />
    </button>
  );
};

NavigationArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['nextArrow', 'prevArrow']).isRequired,
  imgSrc: PropTypes.string.isRequired,
};

export default NavigationArrow;
