const dummyImage = {
  titleText: '',
  titleTextColor: '',
  subtitleText: '',
  subtitleTextColor: '',
  buttonText: '',
  buttonTextColor: '',
  buttonLink: {},
  imagePath: '',
  link: '',
  imageAlterText: '',
  buttonBottomBorderColor: '',
  buttonBackgroundHoverColor: '',
  buttonBottomBorderHoverColor: '',
  buttonBackgroundColor: '',
  buttonPosition: 0,
};

const getImageScalingClass = imageScalingOption => {
  switch (imageScalingOption) {
    case 0:
      return 'image-scale-based-on-width';
    case 1:
      return 'image-scale-based-on-height';
    case 2:
      return 'image-scale-do-not-scale';
    case 3:
      return 'image-scale-best-view';
    default:
      return '';
  }
};

export { dummyImage, getImageScalingClass };
