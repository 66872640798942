import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from './button/Button';
import TitleVariant from './titleVariant/TitleVariant';
import styles from './ImageContent.module.scss';

const ImageContent = ({
  titleText,
  titleTextColor,
  subtitleText,
  subtitleTextColor,
  buttonText,
  buttonAltText,
  buttonTextColor,
  buttonBackgroundColor,
  buttonBottomBorderColor,
  buttonBackgroundHoverColor,
  buttonBottomBorderHoverColor,
  buttonLink,
  tabIndex,
}) => {

  if (!titleText && !subtitleText && !buttonText)
    return null;

  return (
    <div
      className={classNames(styles.imageContent, styles.relative)}
    >
      {titleText && (
        <TitleVariant text={titleText} textColor={titleTextColor} />
      )}
      {subtitleText && (
        <TitleVariant text={subtitleText} textColor={subtitleTextColor} />
      )}
      {buttonText && (
        <Button 
          buttonText={buttonText}
          buttonAltText={buttonAltText}
          buttonTextColor={buttonTextColor}
          buttonBackgroundColor={buttonBackgroundColor}
          buttonBottomBorderColor={buttonBottomBorderColor}
          buttonBackgroundHoverColor={buttonBackgroundHoverColor}
          buttonBottomBorderHoverColor={buttonBottomBorderHoverColor}
          buttonLink={buttonLink}
          tabIndex = {tabIndex}
        />
      )}
    </div>
  );
};

ImageContent.propTypes = {
  titleText: PropTypes.string,
  titleTextColor: PropTypes.string,
  subtitleText: PropTypes.string,
  subtitleTextColor: PropTypes.string,
  buttonText: PropTypes.string,
  buttonAltText: PropTypes.string,
  buttonTextColor: PropTypes.string,
  buttonBackgroundColor: PropTypes.string,
  buttonBottomBorderColor: PropTypes.string,
  buttonBackgroundHoverColor: PropTypes.string,
  buttonBottomBorderHoverColor: PropTypes.string,
  buttonLink: PropTypes.object,
  tabIndex:PropTypes.number,
};

export default ImageContent;
