exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.0.43.34\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".ImageSliderContentBlock__NavigationArrow_arrow{height:42px!important;opacity:.6;padding:1px;transition:.35s ease;width:auto!important;z-index:1}.ImageSliderContentBlock__NavigationArrow_arrow:hover{opacity:1}.ImageSliderContentBlock__NavigationArrow_arrow:focus{outline:1px dotted var(--adk-theme-normalText_FontColor);outline-offset:1px}.ImageSliderContentBlock__NavigationArrow_next-arrow{right:3%!important}.ImageSliderContentBlock__NavigationArrow_next-arrow:before{display:none}.ImageSliderContentBlock__NavigationArrow_prev-arrow{left:3%!important}.ImageSliderContentBlock__NavigationArrow_prev-arrow:before{display:none}", ""]);

// exports
exports.locals = {
	"arrow": "ImageSliderContentBlock__NavigationArrow_arrow",
	"next-arrow": "ImageSliderContentBlock__NavigationArrow_next-arrow",
	"nextArrow": "ImageSliderContentBlock__NavigationArrow_next-arrow",
	"prev-arrow": "ImageSliderContentBlock__NavigationArrow_prev-arrow",
	"prevArrow": "ImageSliderContentBlock__NavigationArrow_prev-arrow"
};